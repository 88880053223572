<template>
  <TTView>
    <VRow>
      <VCol>
        <SkillCard :skill="skill" />
      </VCol>
    </VRow>
  </TTView>
</template>

<script>
import { DEFAULT_SKILL } from '../../components/competency-matrix/skills/common';
import SkillCard from '../../components/competency-matrix/skills/SkillCard.vue';

export default {
  name: 'Skill',

  components: {
    SkillCard,
  },

  data() {
    return {
      skill: { ...DEFAULT_SKILL },
    };
  },

  async created() {
    await this.fetch();
  },

  methods: {
    async fetch() {
      try {
        const { skillId: id } = this.$route.params;
        const data = { id };
        const skillResponse = await this.$di.api.CompetencyMatrix.SkillsGet(data);

        this.skill = skillResponse.skill;
      } catch (err) {
        this.$di.notify.errorHandler(err);
        this.$di.redirect.errorHandler404(err);
      } finally {
        // no-finally
      }
    },
  },
};
</script>
