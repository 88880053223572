var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('VCard',[_c('VToolbar',{attrs:{"flat":""}},[_c('VBtn',{attrs:{"icon":""},on:{"click":function($event){return _vm.$router.go(-1)}}},[_c('VIcon',[_vm._v("fal fa-chevron-left")])],1),_c('VToolbarTitle',[_vm._v(" "+_vm._s(_vm.skill.name)+" ")]),_c('VSpacer'),_c('VTooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('VBtn',_vm._g(_vm._b({staticClass:"mx-1",attrs:{"color":"blue","dark":"","exact":"","icon":"","small":""},on:{"click":function($event){return _vm.sendPush(_vm.skill)}}},'VBtn',attrs,false),on),[_c('VIcon',{attrs:{"small":""}},[_vm._v(" fal fa-comments ")])],1)]}}])},[_c('span',[_vm._v("Отправить push уведомление сотрудникам")])]),(_vm.$route.name !== _vm.Names.R_COMPETENCY_MATRIX_SKILL)?_c('VTooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('VBtn',_vm._g(_vm._b({staticClass:"mx-1",attrs:{"to":{ name : _vm.Names.R_COMPETENCY_MATRIX_SKILL, params : { skillId : _vm.skill.id } },"color":"blue","dark":"","exact":"","icon":"","small":""}},'VBtn',attrs,false),on),[_c('VIcon',{attrs:{"small":""}},[_vm._v(" fal fa-eye ")])],1)]}}],null,false,2279983758)},[_c('span',[_vm._v("Просмотр")])]):_vm._e(),_c('VTooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('VBtn',_vm._g(_vm._b({staticClass:"mx-1",attrs:{"to":{ name : _vm.Names.R_COMPETENCY_MATRIX_SKILL_EDIT, params : { skillId : _vm.skill.id } },"color":"orange","dark":"","exact":"","icon":"","small":""}},'VBtn',attrs,false),on),[_c('VIcon',{attrs:{"small":""}},[_vm._v(" fal fa-edit ")])],1)]}}])},[_c('span',[_vm._v("Редактирование")])])],1),_c('VDivider'),_c('VCardText',[_c('VExpansionPanels',{attrs:{"accordion":"","flat":""}},[_c('VExpansionPanel',[_c('VExpansionPanelHeader',[_vm._v("JSON")]),_c('VExpansionPanelContent',[_c('code',{staticClass:"elevation-0 d-block"},[_vm._v(_vm._s(_vm.skill))])])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }