<template>
  <VCard>
    <VToolbar flat>
      <VBtn
        icon
        @click="$router.go(-1)"
      >
        <VIcon>fal fa-chevron-left</VIcon>
      </VBtn>

      <VToolbarTitle>
        {{ skill.name }}
      </VToolbarTitle>

      <VSpacer />
      <VTooltip left>
        <template #activator="{ on, attrs }">
          <VBtn
            class="mx-1"
            color="blue"
            dark
            exact
            icon
            small
            v-bind="attrs"
            @click="sendPush(skill)"
            v-on="on"
          >
            <VIcon small>
              fal fa-comments
            </VIcon>
          </VBtn>
        </template>

        <span>Отправить push уведомление сотрудникам</span>
      </VTooltip>
      <VTooltip
        v-if="$route.name !== Names.R_COMPETENCY_MATRIX_SKILL"
        left
      >
        <template #activator="{ on, attrs }">
          <VBtn
            :to="{ name : Names.R_COMPETENCY_MATRIX_SKILL, params : { skillId : skill.id } }"
            class="mx-1"
            color="blue"
            dark
            exact
            icon
            small
            v-bind="attrs"
            v-on="on"
          >
            <VIcon small>
              fal fa-eye
            </VIcon>
          </VBtn>
        </template>

        <span>Просмотр</span>
      </VTooltip>

      <VTooltip left>
        <template #activator="{ on, attrs }">
          <VBtn
            :to="{ name : Names.R_COMPETENCY_MATRIX_SKILL_EDIT, params : { skillId : skill.id } }"
            class="mx-1"
            color="orange"
            dark
            exact
            icon
            small
            v-bind="attrs"
            v-on="on"
          >
            <VIcon small>
              fal fa-edit
            </VIcon>
          </VBtn>
        </template>

        <span>Редактирование</span>
      </VTooltip>
    </VToolbar>

    <VDivider />

    <VCardText>
      <VExpansionPanels
        accordion
        flat
      >
        <VExpansionPanel>
          <VExpansionPanelHeader>JSON</VExpansionPanelHeader>
          <VExpansionPanelContent>
            <code class="elevation-0 d-block">{{ skill }}</code>
          </VExpansionPanelContent>
        </VExpansionPanel>
      </VExpansionPanels>
    </VCardText>
  </VCard>
</template>

<script>
import { DEFAULT_SKILL } from './common';

export default {
  name: 'SkillCard',

  inject: ['Names'],

  props: {
    skill: {
      type: Object,
      default: () => ({ ...DEFAULT_SKILL }),
    },
  },
  methods: {
    async sendPush(skill) {
      const payload = {
        skillId: skill.id,
      };

      try {
        await this.$di.api.Sysadmin.pushSendSkill(payload);
        this.$di.notify.snackSuccess('Уведомление успешно отправлено');
      } catch (error) {
        this.$di.notify.errorHandler(error);
      }
    },
  },
};
</script>
